import dynamic from 'next/dynamic';

/* eslint-disable no-unused-vars */
export enum MessageCategory {
  success = 'success',
  information = 'information',
  warning = 'warning',
  error = 'error',
  critical = 'critical',
  none = 'none',
}
/* eslint-enable no-unused-vars */

export type MessageCategoryType = `${MessageCategory}`;

export const SimpleMessage = dynamic(
  () => import('components/simlpleMessage/SimpleMessage').then((mod) => mod.SimpleMessage),
  { ssr: false }
);
export const TwoZeroZero = dynamic(
  () => import('components/simlpleMessage/SimpleMessage').then((mod) => mod.TwoZeroZero),
  { ssr: false }
);
export const FourZeroOne = dynamic(
  () => import('components/simlpleMessage/SimpleMessage').then((mod) => mod.FourZeroOne),
  { ssr: false }
);
export const FourZeroThree = dynamic(
  () => import('components/simlpleMessage/SimpleMessage').then((mod) => mod.FourZeroThree),
  { ssr: false }
);
export const FourZeroFour = dynamic(
  () => import('components/simlpleMessage/SimpleMessage').then((mod) => mod.FourZeroFour),
  { ssr: false }
);
export const FiveZeroZero = dynamic(
  () => import('components/simlpleMessage/SimpleMessage').then((mod) => mod.FiveZeroZero),
  { ssr: false }
);
